import { useNavigate } from 'react-router-dom';

import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { useTheme } from '../../../hooks/useTheme';

import './navigation-segment.scss';

const NavigationSegment = ({
  label,
  route,
  showNotification,
  count,
  icon: Icon,
  onClickOverride
}) => {
  const { themeConfig } = useTheme();
  const navigate = useNavigate();
  const { isCurrentRoute } = useAppNavigation();

  const active = isCurrentRoute(route);

  const notificationColor = themeConfig?.primary?.notification?.variant;

  const handleClick = () => {
    if (onClickOverride) {
      return onClickOverride();
    }

    return navigate(route, { replace: true });
  };

  return (
    <div className="navigation-segment" onClick={handleClick}>
      <div>
        <div className="navigation-segment__badge">
          <div
            className={`${active ? 'navigation-segment__anim-enter' : ''}`}
            style={{
              background: active ? themeConfig?.primary?.navigation?.badge : 'transparent'
            }}
          />
          <div className="navigation-segment__image">
            <Icon width={24} heigh={24} style={{ color: themeConfig?.primary?.icon }} />
          </div>
          {showNotification && (
            <div
              className="navigation-segment__notification"
              style={{ background: notificationColor }}
            >
              <span style={{ color: themeConfig?.primary?.text?.DEFAULT }}>{count}</span>
            </div>
          )}
        </div>
      </div>
      <span style={{ color: themeConfig?.primary?.text?.DEFAULT }}>{label}</span>
    </div>
  );
};

export default NavigationSegment;
