export const STORAGE_KEYS = {
  CART: 'cart',
  DELIVERY_ADDRESS: 'delivery-address'
};

export const saveToLocalStorage = (key, data) => {
  if (!key || typeof key !== 'string') {
    throw new Error('Invalid storage key.');
  }
  if (data && typeof data === 'object') {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    // eslint-disable-next-line
    console.error('Invalid data. Only objects can be stored.');
  }
};

export const getFromLocalStorage = (key) => {
  if (!key || typeof key !== 'string') {
    throw new Error('Invalid storage key.');
  }
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};
