import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { useCart } from '../../../hooks/useCart';
import { useMenus } from '../../../hooks/useMenus';

import './create-meal.scss';
import { CustomMealItem } from './item/CustomMealItem';

import { Currency } from '../../../utils/currency/Currency';
import Button from '../../common/button/Button';
import { QuantitySelector } from '../../quantity-selector/QuantitySelector';

export const CreateMeal = () => {
  const { setShowCreateMealModal } = useAppNavigation();
  const { customFields } = useMenus();
  const [form, setForm] = useState({ quantity: 1 });
  const { addToCart } = useCart();

  const handleOnChange = (name, value) => {
    setForm((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleQuantityChange = (value) => {
    setForm((prevData) => {
      const quantity = (prevData['quantity'] ?? 1) + value;
      return {
        ...prevData,
        quantity: quantity < 1 ? 1 : quantity
      };
    });
  };

  const validForm = useMemo(() => {
    return (
      customFields.some((field) => {
        const formKey = `field-${field.id}`;
        const sizeKey = `size-${field.id}`;

        return form[formKey] && form[sizeKey];
      }) &&
      !customFields.some((field) => {
        const formKey = `field-${field.id}`;
        const sizeKey = `size-${field.id}`;

        return form[formKey] && !form[sizeKey];
      })
    );
  }, [form, customFields]);

  const subTotal = useMemo(() => {
    let total = 0;

    for (const [key, value] of Object.entries(form)) {
      if (key.startsWith('field-')) {
        const fieldId = key.replace('field-', '');
        const sizeKey = `size-${fieldId}`;
        const size = form[sizeKey];

        // Find the group and field item
        const field = customFields.find((field) => field.id === fieldId);
        if (field) {
          const fieldItem = field.fieldItems?.find((fieldItem) => fieldItem.id === value);
          total += (size ?? 0) * (fieldItem.price ?? 0);
        }
      }
    }

    return total;
  }, [form, customFields]);

  const totalPrice = useMemo(() => {
    return subTotal * (form['quantity'] ?? 1);
  }, [subTotal, form]);

  const handleSubmit = () => {
    if (validForm) {
      let description = '';
      const quantity = form['quantity'];

      customFields.forEach((customField) => {
        const fieldId = form[`field-${customField.id}`];
        const size = form[`size-${customField.id}`];

        const field = customField.fieldItems.find((field) => field.id === fieldId);

        if (field) {
          // const size = field.sizes.find((size) => size.id === sizeId);

          // description = `${description} 1 x ${description ? '\n' : ''}${field.name} @ ${formatCurrency(size.price)}`;
          description = `${description}${description ? ', ' : ''}${field.name} ${size}g`;
        }
      });

      addToCart({
        id: uuidv4().toString(),
        name: 'Custom Meal',
        description,
        imageUrl: '/images/menu-items/default.jpg',
        price: subTotal,
        quantity: quantity
      });

      setForm({ quantity: 1 });
    }
  };

  return (
    <div className="create-meal">
      <div>
        <h1>Create Meals</h1>
      </div>
      <div className="create-meal__content">
        <div>
          <span>Quantity</span>
          <QuantitySelector
            quantity={form?.quantity}
            onIncrement={() => handleQuantityChange(1)}
            onDecrement={() => handleQuantityChange(-1)}
          />
        </div>
        <form>
          {customFields.map((field) => (
            <CustomMealItem key={field.id} item={field} onChange={handleOnChange} form={form} />
          ))}
        </form>
      </div>
      <div className="create-meal__footer">
        <div>
          <div>
            <span>Total</span>
            <Currency value={totalPrice} />
          </div>
          <span>
            ({form['quantity']} x <Currency value={totalPrice / form['quantity']} />)
          </span>
        </div>
        <Button disabled={!validForm} onClick={handleSubmit}>
          ADD TO CART
        </Button>
        <Button secondary onClick={() => setShowCreateMealModal(false)}>
          DONE
        </Button>
      </div>
    </div>
  );
};
