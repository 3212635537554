import { useMemo } from 'react';

import { useCart } from '../../../../hooks/useCart';

import { ReactComponent as MinusIcon } from '../../../../icons/minus-circular.svg';
import { ReactComponent as PlusIcon } from '../../../../icons/plus-circular.svg';

import './menu-list-item.scss';

import { Currency } from '../../../../utils/currency/Currency';
import { QuantitySelector } from '../../../quantity-selector/QuantitySelector';

export const MenuListItem = ({ item }) => {
  const { addToCart, removeFromCart, cart, isItemInCart, incrementItem, decrementItem } = useCart();

  const isInCart = useMemo(() => {
    return isItemInCart(item);
    //eslint-disable-next-line
  }, [cart]);

  const cartItem = useMemo(() => {
    return cart.items.find((cartItem) => cartItem.id === item.id);
  }, [cart, item]);

  return (
    <div className="menu-list-item">
      <img src={item.imageUrl} alt={`Menu List Item ${item.id}`} />
      <div>
        <div>
          <span className="menu-list-item__name">{item.name}</span>
          <Currency className="menu-list-item__price" value={item.price} />
        </div>
        <div>
          <span className="menu-list-item__description">{item.description}</span>
          {!isInCart && (
            <PlusIcon
              className="menu-list-item__add"
              width={24}
              height={24}
              onClick={() => addToCart(item)}
            />
          )}
        </div>
        {isInCart && (
          <div className="menu-list-item__quantity">
            <QuantitySelector
              quantity={cartItem.quantity}
              onIncrement={() => incrementItem(item)}
              onDecrement={() => decrementItem(item)}
            />
            <MinusIcon width={24} height={24} onClick={() => removeFromCart(item)} />
          </div>
        )}
      </div>
    </div>
  );
};
