import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

import { CREATE_ORDER_REQUEST, SET_ORDER } from './order.action';

import { createPostRequest } from '../tools/tools';

function* createOrder({ order, user, onComplete }) {
  try {
    const [endpoint, requestOptions] = yield createPostRequest(user, '/order', order);

    const response = yield axios(endpoint, requestOptions);

    yield put({ type: SET_ORDER, data: response.data, error: undefined });
    if (onComplete) yield onComplete(response.data, null);
  } catch (error) {
    yield put({ type: SET_ORDER, data: undefined, error });
    if (onComplete) yield onComplete(null, error);
  }
}

export default function* watchOrders() {
  yield takeLatest(CREATE_ORDER_REQUEST, createOrder);
}
