import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';
import { useCart } from '../../hooks/useCart';
import { useMobile } from '../../hooks/useMobile';
import { useTheme } from '../../hooks/useTheme';

import { ROUTE_PATH } from '../../route/utils';

import { ReactComponent as Account } from '../../icons/account.svg';
import { ReactComponent as Cart } from '../../icons/cart.svg';
import { ReactComponent as Fire } from '../../icons/fire.svg';
import { ReactComponent as Logo } from '../../icons/logo.svg';

import NavigationPopup from './navigation-popup/NavigationPopup';
import NavigationSegment from './navigation-segment/NavigationSegment';
import './navigation.scss';

import Popup from '../common/popup/Popup';
import Header from '../header/Header';
import { CreateMealModal } from '../modal/create-meal/CreateMealModal';
import { OrderModal } from '../modal/order/OrderModal';
import { OrderStatusModal } from '../modal/order/status/OrderStatusModal';
import CartSidebar from '../page/cart/CartSidebar';

const Navigation = ({ children }) => {
  const { themeConfig } = useTheme();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const triggerRef = useRef(null);
  const { signOut } = useAuth();
  const isMobile = useMobile();
  const { cartOpen, setCartOpen, cartCount } = useCart();

  useEffect(() => {
    if (!isMobile) {
      navigate(ROUTE_PATH.MENU, { replace: true });
      setCartOpen(false);
    }
    //eslint-disable-next-line
  }, [isMobile]);

  const handleOpenCart = () => {
    if (isMobile) {
      setCartOpen(false);
      return navigate(ROUTE_PATH.CART, { replace: true });
    } else {
      setCartOpen(!cartOpen);
    }
  };

  const handleButtonClick = () => {
    setIsVisible(!isVisible);
  };

  const handlePopperClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      <nav
        className="navigation"
        style={{ background: themeConfig?.secondary?.background?.variant }}
      >
        <Logo width={41} height={48} />
        <NavigationSegment label="Menu" icon={Fire} route={ROUTE_PATH.MENU} />
        <NavigationSegment
          label="Cart"
          icon={Cart}
          route={ROUTE_PATH.CART}
          onClickOverride={handleOpenCart}
          count={cartCount}
          showNotification={cartCount > 0}
        />
        {/*TODO Implement wallet feature in v2*/}
        {/*<NavigationSegment label="Wallet" icon={Wallet} route={ROUTE_PATH.WALLET} />*/}
        <div>
          <div ref={triggerRef}>
            <NavigationSegment label="Account" icon={Account} onClickOverride={handleButtonClick} />
            {!isMobile && (
              <Popup
                isVisible={isVisible}
                triggerRef={triggerRef}
                onClose={handlePopperClose}
                offset={{ top: 0, left: 47 }}
                width={348}
              >
                <NavigationPopup onSignOutClick={() => signOut()} className="" />
              </Popup>
            )}
            {isMobile && (
              <div
                className={`navigation__popup--mobile ${
                  isVisible ? 'navigation__popup--mobile--visible' : ''
                }`}
                onClick={handleButtonClick}
              >
                {isVisible && <div className="navigation__overlay" />}
                <NavigationPopup onSignOutClick={() => signOut()} />
              </div>
            )}
          </div>
        </div>
      </nav>
      <section className="navigation__content">
        <Header />
        <div>{children}</div>
      </section>
      <section className="navigation__sidebar">{cartOpen && <CartSidebar />}</section>
      <CreateMealModal />
      <OrderModal />
      <OrderStatusModal />
    </>
  );
};

export default Navigation;
