import { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { useMobile } from '../../../hooks/useMobile';
import { useTheme } from '../../../hooks/useTheme';

import GoogleAddressSelect from './google/GoogleAddressSelect';

import { getFirestoreValue } from '../../../utils/firestore/tools';

export const AddressSelectModal = ({ open, onOpen }) => {
  const { themeConfig } = useTheme();
  const isMobile = useMobile();
  const [googleApiKey, setGoogleApiKey] = useState('');

  useEffect(() => {
    const fetchApiKey = async () => {
      const key = await getFirestoreValue('google_api_key');
      setGoogleApiKey(key);
    };
    fetchApiKey();
  }, []);

  const handleButtonClick = () => {};

  return (
    <>
      {isMobile && (
        <div
          className={`create-meal-modal--mobile ${open ? 'create-meal-modal--mobile--visible' : ''}`}
          onClick={handleButtonClick}
        >
          {open && <div className="create-meal-modal__overlay" onClick={() => onOpen(false)} />}
          <div
            className="create-meal-modal__content"
            style={{ background: themeConfig?.secondary?.background?.variant }}
          >
            {googleApiKey && (
              <GoogleAddressSelect onCancel={() => onOpen(false)} googleApiKey={googleApiKey} />
            )}
          </div>
        </div>
      )}
      {!isMobile && (
        <Modal
          isOpen={open}
          onRequestClose={() => onOpen(false)}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              padding: '20px',
              borderRadius: '8px',
              background: themeConfig?.secondary?.background?.variant,
              border: 'none',
              width: '450px',
              overflow: 'visible'
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 9999
            }
          }}
        >
          {googleApiKey && (
            <GoogleAddressSelect onCancel={() => onOpen(false)} googleApiKey={googleApiKey} />
          )}
        </Modal>
      )}
    </>
  );
};
