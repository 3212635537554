import Modal from 'react-modal';

import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { useMobile } from '../../../hooks/useMobile';
import { useTheme } from '../../../hooks/useTheme';

import { CreateMeal } from './CreateMeal';
import './create-meal-modal.scss';

export const CreateMealModal = () => {
  const { themeConfig } = useTheme();
  const isMobile = useMobile();
  const { showCreateMealModal, setShowCreateMealModal } = useAppNavigation();

  return (
    <>
      {isMobile && (
        <div
          className={`create-meal-modal--mobile ${showCreateMealModal ? 'create-meal-modal--mobile--visible' : ''}`}
        >
          {showCreateMealModal && (
            <div
              className="create-meal-modal__overlay"
              onClick={() => setShowCreateMealModal(false)}
            />
          )}
          <div
            className="create-meal-modal__content"
            style={{ background: themeConfig?.secondary?.background?.variant }}
          >
            <CreateMeal />
          </div>
        </div>
      )}
      {!isMobile && (
        <Modal
          isOpen={showCreateMealModal}
          onRequestClose={() => setShowCreateMealModal(false)}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              padding: '20px',
              borderRadius: '8px',
              background: themeConfig?.secondary?.background?.variant,
              border: 'none',
              width: '500px',
              overflow: 'visible'
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 9999
            }
          }}
        >
          <CreateMeal />
        </Modal>
      )}
    </>
  );
};
