import { useMemo } from 'react';

import './custom-meal-item.scss';

import Input from '../../../common/input/Input';
import { Select } from '../../../common/select/Select';

export const CustomMealItem = ({ item, form, onChange }) => {
  const fieldId = useMemo(() => `field-${item.id}`, [item]);
  const sizeId = useMemo(() => `size-${item.id}`, [item]);

  return (
    <div className="custom-meal-item">
      <div>
        <img src={item.imageUrl} alt="category" />
      </div>
      <div>
        <Select
          id={fieldId}
          value={form[fieldId] ?? 'None Selected'}
          onChange={onChange}
          items={item.fieldItems}
          label={item.name}
          isRelative={false}
        />
      </div>
      <div>
        <Input
          id={sizeId}
          value={form[sizeId]}
          name={sizeId}
          type="number"
          onChange={(item) => onChange(item.name, item.value)}
          label="Size (g)"
        />
      </div>
    </div>
  );
};
