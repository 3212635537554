import { useMemo, useCallback, useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { AppNavigationContext } from '../context/AppNavigationContext';

import { ROUTE_PATH } from '../route/utils';

const AppNavigationProvider = ({ children }) => {
  const location = useLocation();
  const [showCreateMealModal, setShowCreateMealModal] = useState(false);
  const [showOrderStatusModal, setShowOrderStatusModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const order = searchParams.get('order');

    if (order && !showOrderStatusModal) {
      const success = searchParams.get('success') === 'true';
      setOrderStatus({
        order,
        success
      });
      setShowOrderStatusModal(!!order);
      navigate('/');
    }
    //eslint-disable-next-line
  }, [searchParams, showOrderStatusModal]);

  const isCurrentRoute = useCallback(
    (route) => {
      return location.pathname === route;
    },
    [location]
  );

  const memoizedAppHeader = useMemo(() => {
    return isCurrentRoute(ROUTE_PATH.MENU);
  }, [isCurrentRoute]);

  return (
    <AppNavigationContext.Provider
      value={{
        isCurrentRoute,
        showAppHeader: memoizedAppHeader,
        showCreateMealModal,
        setShowCreateMealModal,
        showOrderStatusModal,
        setShowOrderStatusModal,
        orderStatus
      }}
    >
      {children}
    </AppNavigationContext.Provider>
  );
};

export default AppNavigationProvider;
