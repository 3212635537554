import React from 'react';

import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { useMenus } from '../../../hooks/useMenus';
import { useMobile } from '../../../hooks/useMobile';
import { useTheme } from '../../../hooks/useTheme';

import { LAYOUT_GRID, LAYOUT_LIST } from '../../../provider/MenuProvider';

import { ReactComponent as LayoutGridIcon } from '../../../icons/layout-grid.svg';
import { ReactComponent as LayoutListIcon } from '../../../icons/layout-list.svg';
import { ReactComponent as MealIcon } from '../../../icons/meal.svg';

import './menu.scss';

import { Categories } from '../../category/Categories';
import Button from '../../common/button/Button';
import Search from '../../common/search/Search';
import { EmptyState } from '../../empty-state/EmptyState';
import Hero from '../../hero/Hero';
import { MenuGrid } from '../../menu/grid/MenuGrid';
import { MenuList } from '../../menu/list/MenuList';
import Navigation from '../../navigation/Navigation';
import { Promotions } from '../../promotion/Promotions';
import { Sidebar } from '../../sidebar/Sidebar';

const Menu = () => {
  const isMobile = useMobile();
  const { promotions, categories, menus, query, menuLayout, setMenuLayout, menusLoading } =
    useMenus();
  const { setShowCreateMealModal } = useAppNavigation();
  const { themeConfig } = useTheme();
  const backgroundColor = themeConfig?.secondary?.background?.DEFAULT;
  const textColor = themeConfig?.primary?.text?.DEFAULT;

  const handleCreateMealClick = (e) => {
    e.stopPropagation();
    setShowCreateMealModal(true);
  };

  const splitGrid = (item, size, index) => {
    if (!isMobile) {
      return (
        <MenuGrid
          key={`${item.title} ${index}`}
          title={item.title}
          items={item.menuItems}
          loading={menusLoading}
          showTitle={index === 0}
        />
      );
    }

    const splitItems = Array.from({ length: Math.ceil(item.menuItems.length / size) }, (_, i) =>
      item.menuItems.slice(i * size, i * size + size)
    );

    return splitItems.map((list, index) => (
      <MenuGrid
        key={`${item.title} ${index}`}
        title={item.title}
        items={list}
        loading={menusLoading}
        showTitle={index === 0}
      />
    ));
  };

  return (
    <>
      <Navigation>
        <div className="page-menu">
          <div>
            <div>
              {!isMobile && (
                <div>
                  {menuLayout === LAYOUT_GRID && (
                    <LayoutListIcon
                      key={LAYOUT_GRID}
                      onClick={() => setMenuLayout(LAYOUT_LIST)}
                      style={{ background: backgroundColor, color: textColor }}
                    />
                  )}
                  {menuLayout === LAYOUT_LIST && (
                    <LayoutGridIcon
                      key={LAYOUT_LIST}
                      onClick={() => setMenuLayout(LAYOUT_GRID)}
                      style={{ background: backgroundColor, color: textColor }}
                    />
                  )}
                  <Search />
                  <Button className="page-menu__action-button" onClick={handleCreateMealClick}>
                    <MealIcon width={24} height={24} />
                    <span>CREATE MEAL</span>
                  </Button>
                </div>
              )}
              {!query && <Hero onClick={handleCreateMealClick} />}
              {!query && <Promotions promotions={promotions} />}
              {!query && <Categories categories={categories} />}
              {menus.length > 0 && (
                <div className="page-menu__menus">
                  {!menusLoading &&
                    menus.map((item, index) =>
                      menuLayout === LAYOUT_LIST ? (
                        <MenuList
                          key={`${item.title} ${index}`}
                          title={item.title}
                          items={item.menuItems}
                        />
                      ) : (
                        splitGrid(item, 10, index)
                      )
                    )}
                  {menusLoading && (
                    <MenusShimmer rows={2} menuLayout={menuLayout} loading={menusLoading} />
                  )}
                </div>
              )}
              {!menusLoading && menus.length === 0 && (
                <div className="page-menu__empty">
                  <EmptyState
                    icon={() => <img src="/images/empty-menu.png" alt="empty menu" />}
                    title="No results found!"
                    description="We couldn't find any items matching your search.
                              Try exploring other categories or creating your own custom meal!"
                  />
                </div>
              )}
            </div>
          </div>
          {!isMobile && <Sidebar />}
          {isMobile && (
            <Button
              className="page-menu__action-button page-menu__action-button--fab"
              onClick={handleCreateMealClick}
            >
              <MealIcon width={24} height={24} />
              <span>CREATE MEAL</span>
            </Button>
          )}
        </div>
      </Navigation>
    </>
  );
};

const MenusShimmer = ({ rows, menuLayout, loading }) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
    {Array.from(Array(rows).keys()).map((key) =>
      menuLayout === LAYOUT_LIST ? (
        <MenuList key={key} loading={loading} />
      ) : (
        <MenuGrid key={key} loading={loading} />
      )
    )}
  </div>
);

export default Menu;
