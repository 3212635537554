import { ReactComponent as MinusIcon } from '../../icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';

import './quantity-selector.scss';

export const QuantitySelector = ({ quantity, onIncrement, onDecrement }) => (
  <div className="quantity-selector">
    <MinusIcon width={20} height={20} onClick={onDecrement} />
    <span>{quantity}</span>
    <PlusIcon width={20} height={20} onClick={onIncrement} />
  </div>
);
