import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';

export const getFirestoreValue = async (key) => {
  const config = getRemoteConfig();

  config.settings = {
    minimumFetchIntervalMillis: 3600000
  };
  await fetchAndActivate(config);

  const environment = process.env.REACT_APP_NODE_ENV;
  return getValue(config, `${environment}_${key}`).asString();
};
