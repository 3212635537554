import './empty-state.scss';

import Button from '../common/button/Button';

export const EmptyState = ({
  title,
  description,
  icon: Icon,
  action = null,
  onActionClick = () => {}
}) => (
  <div className="empty-state">
    <Icon width={56} height={56} />
    {title && <span>{title}</span>}
    {description && <span>{description}</span>}
    {action && <Button onClick={onActionClick}>{action}</Button>}
  </div>
);
