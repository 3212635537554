export const formatCurrency = (amount) => {
  const formatter = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return formatter.format(amount);
};

export const convertToAddressString = (address, includeExtras = false) => {
  if (!address) {
    return null;
  }

  let properties = [];

  if (includeExtras) {
    properties.push('unitNumber');
    properties.push('complex');
  }

  properties.push('streetAddress', 'suburb', 'city', 'province', 'postalCode');

  const addressParts = properties
    .map((prop) => address[prop] || '')
    .filter((part) => part.trim() !== '');

  return addressParts.length > 0 ? addressParts.join(', ') : null;
};
