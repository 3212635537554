import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { CartContext } from '../context/CartContext';

import { convertToAddressString } from '../utils/currency/tools';
import { getFromLocalStorage, saveToLocalStorage, STORAGE_KEYS } from '../utils/storage/storage';

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState();
  const [cartOpen, setCartOpen] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const order = useSelector(({ orders }) => orders.order);
  const serviceFeePercentage = 0.05;
  const deliveryFee = 50;

  const [address, setAddress] = useState(
    convertToAddressString(getFromLocalStorage(STORAGE_KEYS.DELIVERY_ADDRESS), true)
  );

  const location = useMemo(() => {
    const storedAddress = getFromLocalStorage(STORAGE_KEYS.DELIVERY_ADDRESS);
    return storedAddress?.location;
    //eslint-disable-next-line
  }, [address]);

  const memoizedCart = useMemo(
    () => ({
      items: cart?.items ?? [],
      id: cart?.id ?? uuidv4()
    }),
    [cart]
  );

  useEffect(() => {
    const storedCart = getFromLocalStorage(STORAGE_KEYS.CART);
    setCart(storedCart);
  }, []);

  const memoizedCartCount = useMemo(() => {
    return memoizedCart.items.reduce((total, item) => {
      return total + item.quantity;
    }, 0);
  }, [memoizedCart]);

  const handleSetCart = (cart) => {
    setCart(cart);
    saveToLocalStorage(STORAGE_KEYS.CART, cart);
  };

  const handleAddToCart = (item) => {
    handleSetCart({
      ...memoizedCart,
      items: [
        ...memoizedCart.items,
        {
          ...item,
          quantity: item.quantity ?? 1
        }
      ]
    });
  };

  const handleRemoveFromCart = (item) => {
    handleSetCart({
      items: memoizedCart.items.filter((cartItem) => cartItem.id !== item.id)
    });
  };

  const handleIncrementItem = (item) => {
    handleSetCart({
      items: memoizedCart.items.map((cartItem) =>
        cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
      )
    });
  };

  const handleDecrementItem = (item) => {
    handleSetCart({
      items: memoizedCart.items
        .map((cartItem) =>
          cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity - 1 } : cartItem
        )
        .filter((cartItem) => cartItem.quantity > 0)
    });
  };

  const memoizedCartSubTotal = useMemo(() => {
    return memoizedCart.items.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
  }, [memoizedCart]);

  const handleIsItemInCart = (item) => {
    return memoizedCart.items.find((cartItem) => cartItem.id === item.id);
  };

  const serviceFee = useMemo(
    () => memoizedCartSubTotal * serviceFeePercentage,
    [memoizedCartSubTotal]
  );

  const memoizedCartTotal = useMemo(() => {
    return (
      memoizedCartSubTotal * (1 + serviceFeePercentage) +
      (address && memoizedCartCount > 0 ? deliveryFee : 0)
    );
    //eslint-disable-next-line
  }, [memoizedCartSubTotal, address]);

  return (
    <CartContext.Provider
      value={{
        cart: memoizedCart,
        setCart: handleSetCart,
        cartOpen,
        setCartOpen,
        cartCount: memoizedCartCount,
        addToCart: handleAddToCart,
        incrementItem: handleIncrementItem,
        decrementItem: handleDecrementItem,
        removeFromCart: handleRemoveFromCart,
        isItemInCart: handleIsItemInCart,
        cartTotal: memoizedCartTotal,
        address,
        setAddress,
        location,
        showOrderModal,
        setShowOrderModal,
        order,
        serviceFee,
        deliveryFee
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
