import { useCart } from '../../../../hooks/useCart';

import { ReactComponent as MinusIcon } from '../../../../icons/minus-circular.svg';

import './cart-item.scss';

import { Currency } from '../../../../utils/currency/Currency';
import { QuantitySelector } from '../../../quantity-selector/QuantitySelector';

export const CartItem = ({ item, onRemove }) => {
  const { removeFromCart, incrementItem, decrementItem } = useCart();

  return (
    <div className="cart-item">
      <img src={item.imageUrl} alt={`Cart Item ${item.id}`} />
      <div>
        <div>
          <span className="cart__name">{item.name}</span>
          <Currency className="cart-item__price" value={item.price} />
        </div>
        <div>
          <span className="cart-item__description">{item.description}</span>
        </div>
        <div className="cart-item__actions">
          <QuantitySelector
            quantity={item.quantity}
            onIncrement={() => incrementItem(item)}
            onDecrement={() => decrementItem(item)}
          />
          <MinusIcon
            className="cart-item__remove"
            width={24}
            height={24}
            onClick={() => removeFromCart(item)}
          />
        </div>
      </div>
    </div>
  );
};
