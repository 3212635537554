import React, { useEffect } from 'react';
import Modal from 'react-modal';

import { useCart } from '../../../hooks/useCart';
import { useMobile } from '../../../hooks/useMobile';
import { useTheme } from '../../../hooks/useTheme';

import { Order } from './Order';
import './order-modal.scss';

export const OrderModal = () => {
  const { showOrderModal, setShowOrderModal, setCartOpen } = useCart();
  const { themeConfig } = useTheme();
  const isMobile = useMobile();

  useEffect(() => {
    setCartOpen(false);
  }, [setCartOpen]);

  return (
    <>
      {isMobile && (
        <div
          className={`order-modal--mobile ${showOrderModal ? 'order-modal--mobile--visible' : ''}`}
        >
          {showOrderModal && (
            <div className="order-modal__overlay" onClick={() => setShowOrderModal(false)} />
          )}
          <div
            className="order-modal__content"
            style={{ background: themeConfig?.secondary?.background?.variant }}
          >
            <Order />
          </div>
        </div>
      )}
      {!isMobile && (
        <Modal
          isOpen={showOrderModal}
          onRequestClose={() => setShowOrderModal(false)}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              padding: '20px',
              borderRadius: '8px',
              background: themeConfig?.secondary?.background?.variant,
              border: 'none',
              width: '90%',
              maxWidth: '400px',
              overflow: 'visible'
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              zIndex: 9999
            }
          }}
        >
          <Order />
        </Modal>
      )}
    </>
  );
};
