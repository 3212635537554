import Cart from './Cart';

import Navigation from '../../navigation/Navigation';

const CartPage = () => {
  return (
    <Navigation>
      <Cart />
    </Navigation>
  );
};

export default CartPage;
