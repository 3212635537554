import { Route } from 'react-router-dom';

import Login from '../component/login/Login';
import CartPage from '../component/page/cart/CartPage';
import Menu from '../component/page/menu/Menu';
import NotFound from '../component/page/not-found/NotFound';
import Wallet from '../component/page/wallet/Wallet';

import ProtectedRoute from './ProtectedRoute';
import { ROUTE_PATH } from './utils';

export const appRoutes = () => (
  <Route errorElement={<NotFound />} path="/">
    <Route
      exact
      path={ROUTE_PATH.MENU}
      element={
        <ProtectedRoute>
          <Menu />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path={ROUTE_PATH.CART}
      element={
        <ProtectedRoute>
          <CartPage />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path={ROUTE_PATH.WALLET}
      element={
        <ProtectedRoute>
          <Wallet />
        </ProtectedRoute>
      }
    />
    <Route exact path={ROUTE_PATH.LOGIN} element={<Login />} />
  </Route>
);
