import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';

export const createGetRequest = async (user, endpoint) => {
  const config = getRemoteConfig();

  config.settings = {
    minimumFetchIntervalMillis: 3600000
  };
  await fetchAndActivate(config);

  const environment = process.env.REACT_APP_NODE_ENV;
  const apiEndpoint = getValue(config, `${environment}_api_endpoint`).asString();

  return [
    `${apiEndpoint}${endpoint}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.accessToken}`
      },
      method: 'GET'
    }
  ];
};

export const createPostRequest = async (user, endpoint, data) => {
  const config = getRemoteConfig();

  config.settings = {
    minimumFetchIntervalMillis: 3600000
  };
  await fetchAndActivate(config);

  const environment = process.env.REACT_APP_NODE_ENV;
  const apiEndpoint = getValue(config, `${environment}_api_endpoint`).asString();

  return [
    `${apiEndpoint}${endpoint}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.accessToken}`
      },
      method: 'POST',
      data
    }
  ];
};
