import { forwardRef } from 'react';

import { useTheme } from '../../../hooks/useTheme';

import './input.scss';

export const Input = forwardRef(
  (
    {
      label,
      type,
      name,
      value,
      placeholder,
      onChange,
      onFocus,
      required,
      icon: Icon,
      clearIcon: ClearIcon
    },
    ref
  ) => {
    const { themeConfig } = useTheme();
    const backgroundColor = themeConfig?.secondary?.background?.DEFAULT;
    const textColor = themeConfig?.primary?.text?.DEFAULT;

    const handleOnChange = (event) => {
      const { name, value } = event.target;
      onChange({ name, value });
    };

    return (
      <div className="form-input" style={{ background: backgroundColor, color: textColor }}>
        <label htmlFor={name}>{label}</label>
        {Icon && <Icon width={20} height={20} style={{ color: themeConfig?.primary?.icon }} />}
        <input
          ref={ref}
          type={type}
          id={name}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handleOnChange}
          onFocus={onFocus}
          required={required}
          style={Icon ? { paddingLeft: 40 } : {}}
        />
        {ClearIcon && value && (
          <ClearIcon
            width={20}
            height={20}
            style={{ color: themeConfig?.primary?.icon }}
            onClick={() => onChange({ name, value: '' })}
          />
        )}
      </div>
    );
  }
);

export default Input;
