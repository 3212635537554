import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import { ReactComponent as ArrowDownICon } from '../../../icons/arrow-down.svg';

import './select.scss';

export const Select = ({
  id,
  label,
  value,
  onChange,
  items,
  placement = 'bottom-start',
  isRelative = true,
  offset = [0, 8]
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const triggerRef = useRef(null);
  const dropdownRef = useRef(null);

  const { styles, attributes } = usePopper(triggerRef.current, dropdownRef.current, {
    placement,
    strategy: 'absolute',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset
        }
      },
      {
        name: 'preventOverflow',
        options: {
          boundary: 'viewport'
        }
      }
    ]
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionSelect = (event, item) => {
    event.stopPropagation();
    setIsOpen(false);
    onChange(id, item.id);
  };

  return (
    <>
      <label htmlFor={id}>{label}</label>
      <div
        className={`select ${isRelative ? 'select--relative' : ''}`}
        ref={triggerRef}
        onClick={toggleDropdown}
      >
        <div ref={triggerRef}>
          <label htmlFor={id}>
            {items.find((item) => item.id === value)?.name ?? 'None Selected'}
          </label>
          <ArrowDownICon width={20} height={20} />
        </div>

        {isOpen && items.length > 0 && (
          <div
            ref={dropdownRef}
            className="select__menu"
            style={styles.popper}
            {...attributes.popper}
          >
            {items
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => (
                <div
                  key={item.id}
                  className={`select__item ${item.id === value ? 'select__item--selected' : ''}`}
                  onClick={(event) => handleOptionSelect(event, item)}
                >
                  {item.name}
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
};
