import React, { useState, useRef, useMemo } from 'react';

import { useCart } from '../../../../hooks/useCart';
import { useTheme } from '../../../../hooks/useTheme';

import { ReactComponent as CloseIcon } from '../../../../icons/close.svg';
import { ReactComponent as Spinner } from '../../../../icons/loader.svg';
import { ReactComponent as LocationIcon } from '../../../../icons/location.svg';
import { ReactComponent as SearchIcon } from '../../../../icons/search.svg';

import './google-address-select.scss';

import { convertToAddressString } from '../../../../utils/currency/tools';
import {
  getFromLocalStorage,
  saveToLocalStorage,
  STORAGE_KEYS
} from '../../../../utils/storage/storage';
import Button from '../../../common/button/Button';
import Input from '../../../common/input/Input';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const GoogleAddressSelect = ({ onCancel, googleApiKey }) => {
  const { themeConfig } = useTheme();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleApiKey,
    libraries: ['places']
  });
  const { setAddress } = useCart();

  const [addressComponents, setAddressComponents] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({ lat: -34.397, lng: 150.644 });
  const [extraDetails, setExtraDetails] = useState(
    getFromLocalStorage(STORAGE_KEYS.DELIVERY_ADDRESS) ?? {
      complex: '',
      unitNumber: '',
      deliveryInstructions: '',
      contactNumber: ''
    }
  );
  const [errors, setErrors] = useState({});

  const inputRef = useRef(null);

  const initializeAutocomplete = () => {
    if (inputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ['geocode']
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        if (place.geometry) {
          const location = place.geometry.location;
          setCurrentLocation({ lat: location.lat(), lng: location.lng() });
          setAddressComponents(place.address_components);
        }
        inputRef.current = '';
      });
    }
  };

  const selectedAddress = useMemo(() => {
    if (addressComponents && addressComponents.length > 0) {
      return convertToAddressString(extractAddressComponents(addressComponents));
    }

    const storedAddress = getFromLocalStorage(STORAGE_KEYS.DELIVERY_ADDRESS);
    if (storedAddress) {
      setCurrentLocation({ lat: storedAddress.location.lat, lng: storedAddress.location.lng });
      return convertToAddressString(storedAddress);
    }

    return 'None Selected';
  }, [addressComponents]);

  const handleDetailChange = ({ name, value }) => {
    setExtraDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!currentLocation) {
      newErrors.addressComponents = 'Address is required.';
    }
    if (!extraDetails.contactNumber || extraDetails.contactNumber.trim() === '') {
      newErrors.contactNumber = 'Contact number is required.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    if (addressComponents && addressComponents.length > 0) {
      const components = extractAddressComponents(addressComponents);
      const formData = {
        ...extraDetails,
        ...components,
        location: currentLocation
      };

      setAddress(convertToAddressString(formData, true));
      saveToLocalStorage(STORAGE_KEYS.DELIVERY_ADDRESS, formData);
    }

    onCancel();
  };

  if (!isLoaded)
    return (
      <div className="google-address-select__loader">
        <Spinner width={48} height={48} style={{ color: themeConfig?.primary?.DEFAULT }} />
      </div>
    );

  return (
    <form className="google-address-select" onSubmit={handleSubmit}>
      <h1>Select Address</h1>
      <div>
        <div>
          <Input
            type="text"
            ref={inputRef}
            placeholder="Search for a location"
            icon={SearchIcon}
            clearIcon={CloseIcon}
            onChange={() => {}}
            onFocus={initializeAutocomplete}
          />
        </div>

        <div className="google-address-select__map">
          <GoogleMap
            center={currentLocation}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '150px', borderRadius: '8px' }}
          >
            <Marker position={currentLocation} />
          </GoogleMap>
          <div className="google-address-select__location">
            <span>Selected Address</span>
            <div>
              <div>
                <LocationIcon width={24} height={24} />
              </div>
              <span>{selectedAddress}</span>
            </div>
            {errors.addressComponents && <div className="error">{errors.addressComponents}</div>}
          </div>
        </div>

        <div className="google-address-select__details">
          <h2>Additional Details</h2>
          <Input
            type="text"
            name="complex"
            placeholder="Complex or Building Name"
            value={extraDetails.complex}
            onChange={handleDetailChange}
          />
          <Input
            type="text"
            name="unitNumber"
            placeholder="Unit Number"
            value={extraDetails.unitNumber}
            onChange={handleDetailChange}
          />
          <Input
            type="tel"
            name="contactNumber"
            placeholder="Contact Number"
            value={extraDetails.contactNumber}
            onChange={handleDetailChange}
          />
          <textarea
            name="deliveryInstructions"
            placeholder="Delivery Instructions"
            value={extraDetails.deliveryInstructions}
            onChange={(e) => handleDetailChange(e.target)}
            style={{ width: '100%', padding: '8px', height: '100px' }}
          />
        </div>
      </div>

      {errors.contactNumber && <div className="error">{errors.contactNumber}</div>}
      <Button type="submit" onClick={handleSubmit}>
        SAVE CHANGES
      </Button>
      <Button secondary onClick={onCancel}>
        CANCEL
      </Button>
    </form>
  );
};

const extractAddressComponents = (components) => {
  const findComponent = (types) =>
    components.find((component) => types.some((type) => component.types.includes(type)))
      ?.long_name || '';

  return {
    streetAddress: findComponent(['street_number']) + ' ' + findComponent(['route']),
    suburb: findComponent(['sublocality', 'sublocality_level_1']),
    city: findComponent(['locality']),
    province: findComponent(['administrative_area_level_1']),
    postalCode: findComponent(['postal_code'])
  };
};

export default GoogleAddressSelect;
