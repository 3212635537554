import { useAppNavigation } from '../../../../hooks/useAppNavigation';

import { ReactComponent as FailedIcon } from '../../../../icons/failed.svg';
import { ReactComponent as SuccessIcon } from '../../../../icons/success.svg';

import './order-status.scss';

import Button from '../../../common/button/Button';

export const OrderStatus = () => {
  const { orderStatus, setShowOrderStatusModal } = useAppNavigation();
  return (
    <div className="order-status">
      {orderStatus?.success && (
        <>
          <SuccessIcon width={56} height={56} className="order-status__success" />
          <h1>Payment Successful</h1>
          <span>Check your email for order confirmation. Please contact us for any queries.</span>
          <Button
            secondary
            style={{ background: '#4CAF50' }}
            onClick={() => setShowOrderStatusModal(false)}
          >
            OK
          </Button>
        </>
      )}
      {!orderStatus?.success && (
        <>
          <FailedIcon width={56} height={56} className="order-status__failed" />
          <h1>Payment Unsuccessful</h1>
          <span>
            There was a problem processing your payment. Please contact us for any queries.
          </span>
          <Button
            secondary
            style={{ background: '#FF9800' }}
            onClick={() => setShowOrderStatusModal(false)}
          >
            OK
          </Button>
        </>
      )}
    </div>
  );
};
