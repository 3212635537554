import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';

import { GET_CUSTOM_FIELDS_REQUEST, SET_CUSTOM_FIELDS } from './custom-field.action';

import { createGetRequest } from '../tools/tools';

function* getCustomFields({ user, onComplete }) {
  try {
    const [endpoint, requestOptions] = yield createGetRequest(user, '/custom/fields');

    const response = yield axios.get(endpoint, requestOptions);

    const customFields = [];

    response.data.forEach((customField) => {
      customFields.push({ id: customField.id, ...customField });
    });

    yield put({ type: SET_CUSTOM_FIELDS, data: customFields, error: undefined });
    if (onComplete) onComplete(response);
  } catch (error) {
    yield put({ type: SET_CUSTOM_FIELDS, data: undefined, error });
    if (onComplete) onComplete(error);
  }
}

export default function* watchCustomFields() {
  yield takeLatest(GET_CUSTOM_FIELDS_REQUEST, getCustomFields);
}
